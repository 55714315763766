<template>
  <WeModal v-on:close="onClose" v-bind:title="getTitle" modal-width="80">
    <div
      slot="body"
      class="custom-scrollbar pr-3"
      v-bind:class="{ 'custom-scrollbar modal-body-scroll pr-3': !showDetail }"
    >
      <span v-print="printConfig" ref="printButton"> </span>
      <div v-if="!loading && !showDetail" v-bind:id="printConfig.id">
        <WeTable
          v-bind:index="false"
          v-bind:columns="columns"
          v-bind:exportables="exportables"
          v-on:on-export="onExport"
          v-bind:actions="actions"
          v-bind:data="detail.orders"
          v-on:on-action="onAction"
          v-bind:paginatable="paginatable"
          v-bind:page-details="pageDetails"
          v-bind:limitable="limitable"
          v-bind:searchable="searchable"
        />
      </div>
      <WeOrderDetail
        v-else-if="showDetail && ready"
        v-bind:order="orderDetail"
        v-bind:site-logo="siteLogo"
        v-on:on-close="onClose(true)"
      />
      <div class="position-relative" style="min-height: 250px" v-else>
        <WeLoading />
      </div>
    </div>
  </WeModal>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ProductSaleDetail",
  props: {
    detail: {
      default: null,
    },
    loading: {
      default: false,
    },
  },
  data() {
    return {
      ready: false,
      showDetail: false,
      actions: [
        {
          icon: "fas fa-eye",
          class: "btn-sm btn-outline-indigo",
          action: "show-detail",
          tooltip: "Sipariş Detayı",
        },
      ],
      columns: [
        { name: "date", th: "Sipariş Tarihi", type: "date", width: "20%" },
        {
          name: "source",
          th: "Sipariş Kaynağı",
          type: "string",
          width: "20%",
        },
        { name: "order_no", th: "Sipariş No", type: "string", width: "20%" },
        { name: "price", th: "Sipariş Tutarı", type: "currency", width: "20%" },
      ],
      exportables: ["excel", "pdf", "print"],
      paginatable: true,
      pageDetails: true,
      limitable: true,
      searchable: true,
      printConfig: {
        id: "printOrderList",
        popTitle: "Ürün Sipariş Listesi",
      },
    };
  },
  methods: {
    ...mapActions("orderDetailModal", ["getById"]),
    ...mapActions("tableExport", ["exportData"]),
    onAction(data) {
      if (data.key == "show-detail") {
        this.showDetail = true;
        this.ready = false;
        this.getById({
          id: data.row.id,
          onFinish: () => {
            this.showDetail = true;
            this.ready = true;
          },
        });
      }
    },
    onClose(fromDetail = false) {
      if (fromDetail) {
        this.showDetail = false;
      } else {
        this.showDetail = false;
        this.$emit("close");
      }
    },
    onExport(data) {
      if (this.detail.orders && this.detail.orders.length) {
        if (data.name !== "print") {
          let fileName = this.getTitle;
          const filterDates = this.detail.filter.dates;

          if (filterDates && filterDates.length) {
            let startDate = new Date(filterDates[0]).toLocaleDateString(
              "tr-TR"
            );
            let endDate = new Date(filterDates[1]).toLocaleDateString("tr-TR");

            fileName = startDate + " - " + endDate + "_" + fileName;
          }

          this.exportData({
            route: `report/products/${this.detail.id}/orders/export`,
            exportable: data,
            fileName: fileName,
            filter: {
              ...this.detail.filter,
              type: data.name,
            },
          });
        } else {
          this.setPrintableSettings(true);
          this.$refs.printButton.click();

          setTimeout(() => {
            this.setPrintableSettings(false);
          }, 100);
        }
      } else {
        this.$swal({
          title: "Dışarı aktarılacak veya Yazdırılacak veri mevcut değil",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
    },
    setPrintableSettings(print = false) {
      this.paginatable = !print;
      this.pageDetails = !print;
      this.limitable = !print;
      this.searchable = !print;

      this.detail.orders.sort();
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
      orderDetail: (state) => state.orderDetailModal,
    }),
    getTitle() {
      return this.showDetail ? "" : this.detail.title + " - Sipariş Listesi";
    },
    siteLogo() {
      return this.config["site.logo"] || "";
    },
  },
};
</script>
<style lang="scss">
.modal-body-scroll {
  max-height: 500px;
  overflow-x: auto;
}
</style>